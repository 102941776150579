import React from "react";
import { Carousel } from "@material-tailwind/react";

//import assets
import RightArrow from "../../Assets/RIghtArrow";
import LeftArrow from "../../Assets/LeftArrow";

export const Feedback = () => {
  return (
    <Carousel
      className="rounded-xl"
      navigation={({ setActiveIndex, activeIndex, length }) => (
        <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
          {new Array(length).fill("").map((_, i) => (
            <span
              key={i}
              className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                activeIndex === i ? "w-8 bg-primary" : "w-4 bg-gray"
              }`}
              onClick={() => setActiveIndex(i)}
            />
          ))}
        </div>
      )}
      prevArrow={({ handlePrev }) => (
        <a
          onClick={handlePrev}
          className="!absolute top-2/4 left-4 -translate-y-2/4 "
        >
          <LeftArrow />
        </a>
      )}
      nextArrow={({ handleNext }) => (
        <a
          onClick={handleNext}
          className=" !absolute top-2/4 !right-4 -translate-y-2/4"
        >
          <RightArrow />
        </a>
      )}
    >
      <div className="flex flex-col justify-center items-center py-16 text-center px-10">
        <div className="flex flex-col justify-center items-center gap-5 mb-10">
          <div className="shadow-lg rounded-2xl overflow-hidden">
            <img
              src="https://a.storyblok.com/f/191576/1200x800/faa88c639f/round_profil_picture_before_.webp"
              alt="client-img"
              className="w-20 h-20 object-cover"
            />
          </div>
          <p className="text-[20px] text-gray font-helvetica font-light">
          Fantastische Plattform! Ich habe über diese Seite in kürzester Zeit mein Traumhaus gefunden. Die Suchfunktion ist super einfach zu bedienen, und die Auswahl an Immobilien ist beeindruckend. Kann ich jedem nur empfehlen!
          </p>
        </div>
        <div>
          <p className="text-primary font-semibold">Katja Schreiber</p>
          <p className="text-gray font-helvetica font-light">
            Düsseldorf, Deutschland
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center py-16 text-center px-10">
        <div className="flex flex-col justify-center items-center gap-5 mb-10">
          <div className="shadow-lg rounded-2xl overflow-hidden">
            <img
              src="https://www.shutterstock.com/image-photo/head-shot-portrait-close-smiling-600nw-1714666150.jpg"
              alt="client-img"
              className="w-20 h-20 object-cover"
            />
          </div>
          <p className="text-[20px] text-gray font-helvetica font-light">
            Ich war 3 Monate auf der Suche nach einer bezahlbaren Wohnung in
            Berlin, bis ich über ImmoInterest endlich fündig wurde, Vielen Dank!
          </p>
        </div>
        <div>
          <p className="text-primary font-semibold">Theo Müller</p>
          <p className="text-gray font-helvetica font-light">
            Berlin, Deutschland
          </p>
        </div>
      </div>
    </Carousel>
  );
};
