import classNames from "classnames";

export const Button = ({ title, full, variant, ...rest }) => (
  <button
    className={classNames(
      "font-light font-helvetica  text-[16px] px-8 py-3 rounded-full",
      variant == "primary" && "bg-primary text-white",
      variant === "secondary" && "bg-white text-primary",
      full && "w-full"
    )}
    {...rest}
  >
    {title}
  </button>
);

export const LinkButton = ({ title, variant, ...rest }) => (
  <a
    className={classNames(
      "font-light font-helvetica cursor-pointer text-[16px] px-8 py-3 rounded-full",
      variant == "primary" && "bg-primary text-white",
      variant === "secondary" && "bg-white text-primary"
    )}
    {...rest}
  >
    {title}
  </a>
);
