export const Constant = {
  TOKEN: "accessToken",
  FURNISHING: [
    "Balkon / Terrasse",
    "Lift",
    "Neubau",
    "Altbau",
    "Swimmingpool",
    "Haustiere erlaubt",
    "Rollstuhlgängig",
    "Parkplatz / Garage",
    "Minergie",
  ],
  AREA: [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
    { label: "15", value: "15" },
    { label: "20", value: "20" },
    { label: "25", value: "25" },
    { label: "30", value: "30" },
    { label: "35", value: "35" },
  ],
  ROOMS: [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
  ],
  HOUSE_TYPE: [
    { label: "Burgen / Schlösser", value: "Burgen / Schlösser" },
    { label: "Bungalows", value: "Bungalows" },
    { label: "Bauernhäuser", value: "Bauernhäuser" },
    { label: "Dopplhäuser", value: "Dopplhäuser" },
    { label: "Doppelhaushälften", value: "Doppelhaushälften" },
    { label: "Einfamilienhäuser", value: "Einfamilienhäuser" },
    { label: "Fincas", value: "Fincas" },
    { label: "Gewerbeflächen", value: "Gewerbeflächen" },
    { label: "Herrenhäuser", value: "Herrenhäuser" },
    { label: "Mehrfamilienhäuser", value: "Mehrfamilienhäuser" },
    { label: "Reihenendhäuser", value: "Reihenendhäuser" },
    { label: "Reihenmittelhäuser", value: "Reihenmittelhäuser" },
    { label: "Rustico", value: "Rustico" },
    { label: "Stadthaus", value: "Stadthaus" },
    { label: "Villen", value: "Villen" },
    { label: "Zweifamilienhäuser", value: "Zweifamilienhäuser" },
  ],
  APARTMENT_TYPE: [
    { label: "Apartements", value: "Apartements" },
    { label: "Etagenwohnung", value: "Etagenwohnung" },
    { label: "Penthouse-Wohnungen", value: "Penthouse-Wohnungen" },
    { label: "Rohdachböden", value: "Rohdachböden" },
    { label: "Lofts", value: "Lofts" },
    { label: "Maisonettes", value: "Maisonettes" },
    { label: "Terrassenwohnungen", value: "Terrassenwohnungen" },
  ],
  CATEGORY: [
    { label: "Grundstück", value: "Grundstück" },
    { label: "Haus", value: "Haus" },
    { label: "Möbliertes Wohnobjekt", value: "Möbliertes Wohnobjekt" },
    { label: "Wohnung", value: "Wohnung" },
    { label: "Wohnnebenräume", value: "Wohnnebenräume" },
    { label: "Gastronomie", value: "Gastronomie" },
    { label: "Gewerbe/Industrie", value: "Gewerbe/Industrie" },
    { label: "Landwirtschaft", value: "Landwirtschaft" },
    { label: "Parkplatz", value: "Parkplatz" },
    { label: "Haus", value: "Haus" },
    { label: "Möbliertes Wohnobjekt", value: "Möbliertes Wohnobjekt" },
  ],
  NEW_CATEGORY: [
    {
      label: "Grundstück",
      value: "Grundstück",
      sub: [
        { label: "Bauland", value: "Bauland" },
        { label: "Gewerbeland", value: "Gewerbeland" },
      ],
    },
    {
      label: "Haus",
      value: "Haus",
      sub: [
        { label: "Einfamilienhaus", value: "Einfamilienhaus" },
        { label: "Mehrfamilienhaus", value: "Mehrfamilienhaus" },
        { label: "Reihenhaus", value: "Reihenhaus" },
        { label: "Villa", value: "Villa" },
        { label: "Bauernhaus", value: "Bauernhaus" },
        { label: "Doppelhaushälfte", value: "Doppelhaushälfte" },
        { label: "Rustico", value: "Rustico" },
        { label: "Schloss", value: "Schloss" },
        { label: "Stöckli", value: "Stöckli" },
        { label: "Chalet", value: "Chalet" },
        { label: "Villa", value: "Villa" },
        { label: "Terrassenhaus", value: "Terrassenhaus" },
      ],
    },
    {
      label: "Möbliertes Wohnobjekt",
      value: "Möbliertes Wohnobjekt",
      sub: [{ label: "Möbliertes Wohnobjekt", value: "Möbliertes Wohnobjekt" }],
    },
    {
      label: "Wohnung",
      value: "Wohnung",
      sub: [
        { label: "Etagenwohnung", value: "Etagenwohnung" },
        { label: "Attikawohnung", value: "Attikawohnung" },
        { label: "Terrassenwohnung", value: "Terrassenwohnung" },
        { label: "Loft", value: "Loft" },
        { label: "Studio", value: "Studio" },
        { label: "Dachwohnung", value: "Dachwohnung" },
        { label: "Gartenwohnung", value: "Gartenwohnung" },
        { label: "Wohnung", value: "Wohnung" },
        { label: "Einzelzimmer", value: "Einzelzimmer" },
        { label: "Estrichabteil", value: "Estrichabteil" },
        {
          label: "Duplex-/Maisonette-Wohnung",
          value: "Duplex-/Maisonette-Wohnung",
        },
      ],
    },
    {
      label: "Wohnnebenräume",
      value: "Wohnnebenräume",
      sub: [
        { label: "Kellerabteil", value: "Kellerabteil" },
        { label: "Hobbyraum", value: "Hobbyraum" },
      ],
    },
    {
      label: "Gastronomie",
      value: "Gastronomie",
      sub: [
        { label: "Bar", value: "Bar" },
        { label: "Café", value: "Café" },
        { label: "Restaurant", value: "Restaurant" },
        { label: "Hotel", value: "Hotel" },
        { label: "Club/Disco", value: "Club/Disco" },
        { label: "Pub", value: "Pub" },
      ],
    },
    {
      label: "Gewerbe/Industrie",
      value: "Gewerbe/Industrie",
      sub: [
        { label: "Büro", value: "Büro" },
        { label: "Gewerbe", value: "Gewerbe" },
        { label: "Lagerraum", value: "Lagerraum" },
        { label: "Praxis", value: "Praxis" },
        { label: "Gewerbe/Industrie", value: "Gewerbe/Industrie" },
        { label: "Arcade", value: "Arcade" },
        { label: "Atelier", value: "Atelier" },
        { label: "Coiffeursalon", value: "Coiffeursalon" },
        { label: "Einkaufszentrum", value: "Einkaufszentrum" },
        { label: "Einzelgarage", value: "Einzelgarage" },
        { label: "geschäftshaus", value: "geschäftshaus" },
        { label: "Kiosk", value: "Kiosk" },
        { label: "Ladenfläche", value: "Ladenfläche" },
        { label: "Parkplatz", value: "Parkplatz" },
        { label: "Werkstatt", value: "Werkstatt" },
        { label: "Whon-/Geschäftshaus", value: "Whon-/Geschäftshaus" },
      ],
    },
    {
      label: "Landwirtschaft",
      value: "Landwirtschaft",
      sub: [{ label: "Landwirtschaft", value: "Landwirtschaft" }],
    },
    {
      label: "Parkplatz",
      value: "Parkplatz",
      sub: [
        { label: "Doppelgarage", value: "Doppelgarage" },
        { label: "Einzelgarage", value: "Einzelgarage" },
        { label: "Moto Hallenplatz", value: "Moto Hallenplatz" },
        { label: "offener Parkplatz", value: "offener Parkplatz" },
        { label: "Tielfgarage", value: "Tielfgarage" },
        { label: "Unterstand", value: "Unterstand" },
      ],
    },
  ],
  FLOW_SPACE: [
    { label: "Any", valye: "any" },
    { label: "50 m²", value: "50" },
    { label: "100 m²", value: "100" },
    { label: "150 m²", value: "150" },
    { label: "200 m²", value: "200" },
    { label: "250 m²", value: "250" },
    { label: "300 m²", value: "300" },
    { label: "350 m²", value: "350" },
    { label: "400 m²", value: "400" },
    { label: "450 m²", value: "450" },
    { label: "500 m²", value: "500" },
    { label: "550 m²", value: "550" },
    { label: "600 m²", value: "600" },
    { label: "650 m²", value: "650" },
    { label: "700 m²", value: "700" },
    { label: "750 m²", value: "750" },
    { label: "1000 m²", value: "1000" },
    { label: "2000 m²", value: "2000" },
    { label: "3000 m²", value: "3000" },
    { label: "4000 m²", value: "4000" },
    { label: "5000 m²", value: "5000" },
    { label: "10000 m²", value: "10000" },
  ],
};
