import * as React from "react";

function LeftArrow(props) {
  return (
    <svg width={13} height={22} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.121 11l7.94-7.94A1.5 1.5 0 009.939.94l-9 9a1.5 1.5 0 000 2.12l9 9a1.5 1.5 0 002.122-2.12L4.12 11z"
        fill="#889196"
      />
    </svg>
  );
}

export default LeftArrow;
