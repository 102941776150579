import React, { createContext, useContext, useState, useEffect } from "react";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [showAdModal, setShowAdModal] = useState(false);
  const [filters, setFilters] = useState({
    city: "",
    rooms: "",
    minArea: "",
    maxArea: "",
    minPrice: "",
    maxPrice: "",
    furnishing: "",
    minConstructionYear: "",
    maxConstructionYear: "",
    advertise_type: "Mieten",
    category: "",
  });

  const handleShowAdModal = () => setShowAdModal((prevState) => !prevState);
  return (
    <AppContext.Provider
      value={{
        showAdModal,
        handleShowAdModal,
        filters,
        setFilters,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => {
  return useContext(AppContext);
};
