import * as React from "react";

function Room(props) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <g
        clipPath="url(#prefix__clip0_34_1101)"
        stroke="#073937"
        strokeWidth={1.5}
      >
        <path d="M1.773 9.719c-.285-1.978-.427-2.967-.021-3.813.405-.845 1.268-1.36 2.993-2.387L5.784 2.9C7.35 1.967 8.134 1.5 9 1.5c.866 0 1.65.467 3.216 1.4l1.039.619c1.725 1.028 2.588 1.542 2.993 2.387.406.846.264 1.835-.021 3.813l-.21 1.453c-.365 2.54-.547 3.81-1.429 4.57-.881.758-2.173.758-4.758.758H8.17c-2.584 0-3.877 0-4.758-.759-.882-.759-1.064-2.029-1.43-4.57L1.773 9.72z" />
        <path
          d="M6.75 12c.638.473 1.413.75 2.25.75s1.612-.277 2.25-.75"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_34_1101">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Room;
