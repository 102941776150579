import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthHeaders } from "./baseQueries";

export const advertiseApi = createApi({
  reducerPath: "advertiseApi",
  baseQuery: baseQueryWithAuthHeaders,
  tagTypes: ["Advertise"],
  endpoints: (build) => ({
    getAllAdvertise: build.query({
      query: () => ({
        url: "/advertise",
        method: "GET",
      }),
      providesTags: ["Advertise"],
      transformResponse: (response) => response.data,
    }),
    getCities: build.query({
      query: () => ({
        url: "/advertise/cities",
        method: "GET",
      }),
      providesTags: ["Advertise"],
      transformResponse: (response) => response.data,
    }),
    getAllAdvertiseByCity: build.query({
      query: ({ city }) => ({
        url: `/advertise/filter`,
        method: "GET",
        params: {
          city,
        },
      }),
      providesTags: ["Advertise"],
      transformResponse: (response) => response.data,
    }),
    getFilterAdvertise: build.query({
      query: ({
        city,
        minArea,
        rooms,
        minPrice,
        maxPrice,
        minConstructionYear,
        maxConstructionYear,
        advertise_type,
        category,
      }) => ({
        url: `/advertise/filter`,
        method: "GET",
        params: {
          city,
          minArea,
          rooms,
          minPrice,
          maxPrice,
          minConstructionYear,
          maxConstructionYear,
          advertise_type,
          category,
        },
      }),
      providesTags: ["Advertise"],
      transformResponse: (response) => response.data,
    }),
    addAdvertise: build.mutation({
      query: (data) => ({
        url: `/advertise/add`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Advertise"],
    }),
  }),
});

export const {
  useGetAllAdvertiseQuery,
  useGetCitiesQuery,
  useAddAdvertiseMutation,
  useGetAllAdvertiseByCityQuery,
  useGetFilterAdvertiseQuery,
} = advertiseApi;
