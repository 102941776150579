import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

//import components
import { Footer, Navbar, SearchBar } from "../../Components";

//assets
import HeroImage from "../../Assets/background-img2.png";
import BackgroundImage from "../../Assets/background-img3.png";

export default function AboutUsPage() {
  const navigate = useNavigate();
  return (
    <>
      <Navbar />
      {/* hero section */}
      <div className="mt-20 relative overflow-x-hidden">
        <img
          className="object-cover w-full max-w-full md:h-auto h-[400px] object-center"
          src={HeroImage}
          alt="hero-img"
        />
        <div className="!absolute inset-0 flex flex-col justify-center items-center text-center gap-2 md:px-20 px-5">
          <p className="font-georgia md:text-[55px] sm:text-8xl text-white leading-snug">
            Die <br />
            Immobilienfinder
          </p>
          <p className="text-white font-helvetica md:text-[18px] text-[16px] text-md">
            Alles, was du über Uns wissen musst.
          </p>
        </div>
      </div>

      {/* about section */}
      <div>
        <div className="grid lg:grid-cols-2 sm:grid-rows-1 lg:mt-20 mt-20 ">
          <img
            className="object-cover w-full max-w-full md:h-auto h-[400px] object-center"
            src={BackgroundImage}
            alt="background-img"
          />

          <div className="flex flex-col justify-center lg:p-8 p-4 gap-4">
            <p className="lg:text-[44px] text-xl font-georgia text-primary">
              Über Immointerest
            </p>
            <p className="text-[14px] font-helvetica font-light">
            Mit Immointerest haben wir eine intelligente Plattform geschaffen, die Immobilieninteressenten und Vermittler effizient miteinander verbindet. Unser Service vereinfacht die Immobiliensuche und sorgt dafür, dass beide Seiten schneller und gezielter zueinander finden.

Für Immobilieninteressenten bieten wir einen kostenlosen und benutzerfreundlichen Service: Registrieren Sie sich bei uns und hinterlassen Sie Ihre Suchanfrage. Geben Sie an, welche Art von Immobilie Sie suchen, ob Wohnung, Haus oder Grundstück. Wir übernehmen den Rest und informieren alle Immobilienvermittler in einem Umkreis von 30 Kilometern über Ihre Wünsche. So erhalten Sie schnell Angebote, die genau Ihren Vorstellungen entsprechen.

Immobilienvermittler profitieren ebenfalls von unserem System, da sie gezielte Anfragen von Interessenten erhalten, die genau zu ihren Immobilienangeboten passen. Anstatt nach potenziellen Interessenten suchen zu müssen, können Vermittler ihre Immobilien direkt den passenden Kunden anbieten. Dies führt zu einer effizienteren und erfolgreicheren Vermittlung.

Mit Immointerest gestalten wir den Immobilienmarkt transparenter und zielgerichteter. Unsere Plattform sorgt dafür, dass Interessenten passende Immobilien schneller finden und Vermittler ihre Objekte gezielter anbieten können. Wir stehen für eine direkte und reibungslose Kommunikation, die den gesamten Prozess für beide Seiten vereinfacht
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
