import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Constant } from "../../Constant";
import { useApp } from "../../Context";

//import assets
import Magnifying from "../../Assets/Magnifying";
import Box from "../../Assets/Box";
import Filter from "../../Assets/Filter";
import { Radio } from "@material-tailwind/react";

export const SearchBar = ({ handleShowFilter, showType }) => {
  const navigate = useNavigate();
  const { filters, setFilters } = useApp();
  const [result, setResult] = useState([]);
  const [query, setQuery] = useState("");
  const resultsRef = useRef(null);

  const handleSuggestCity = (e) => {
    let text = e.target.value;
    setQuery(text);
    if (text) {
      const config = {
        method: "get",
        url: `https://api.geoapify.com/v1/geocode/autocomplete?text=${text}&apiKey=3ed82eac110f4fabbad64b85f8cbe6c2`,
        headers: {},
      };

      axios(config)
        .then(function (response) {
          setResult(response.data.features);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setResult([]);
    }
  };

  const handleSelectCity = (city) => {
    setQuery(city);
    setResult([]);
    setFilters((prev) => ({
      ...prev,
      city: city,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFilter = () => {
    navigate("/filter");
  };

  return (
    <div className="flex w-full bg-background h-16 rounded-full items-center px-2 py-2 md:gap-5 gap-2 ">
      <div className="relative">
        <div className="flex gap-x-2 items-center">
          <Magnifying />
          <input
            name="city"
            value={query}
            onChange={handleSuggestCity}
            placeholder="Stadt suchen"
            className="text-primary font-light font-helvetica placeholder:text-primary bg-background focus:outline-none"
          />
        </div>
        {result.length > 0 && (
          <div
            ref={resultsRef}
            className="absolute top-12 left-0 right-0 flex flex-col gap-2 bg-background shadow-md rounded-sm p-2 z-10 opacity-90"
          >
            {result.map((item, idx) => (
              <a
                key={idx}
                className="text-sm font-helvetica cursor-pointer hover:bg-blue-gray-300 hover:text-white p-1 text-primary"
                onClick={() => handleSelectCity(item.properties.city)}
              >
                {item.properties.city}
              </a>
            ))}
          </div>
        )}
      </div>

      {/* {showType && (
        <div className="flex gap-2 absolute top-[-40px] ">
          <Radio
            name="Werbetyp"
            label={
              <p className="font-helvetica font-semibold text-white">Mieten</p>
            }
            value="Mieten" // Set the value to the desired option
            checked={filters.advertise_type === "Mieten"} // Add a checked prop to indicate the current state
            onChange={() =>
              setFilters({ ...filters, advertise_type: "Mieten" })
            }
            icon={<div className="w-3 h-3 rounded-full bg-primary" />}
            className="border-2 border-primary bg-white p-0 m-0 transition-all hover:before:opacity-0 focus:border-primary"
            ripple={false}
          />

          <Radio
            name="Werbetyp"
            value="Kaufen" // Set the value to the desired option
            checked={filters.advertise_type === "Kaufen"} // Add a checked prop to indicate the current state
            onChange={() =>
              setFilters({ ...filters, advertise_type: "Kaufen" })
            }
            label={
              <p className="font-helvetica font-semibold text-white">Kaufen</p>
            }
            icon={<div className="w-3 h-3 rounded-full bg-primary" />}
            className="border-2 border-primary bg-white p-0 transition-all hover:before:opacity-0 focus:border-primary"
            ripple={false}
          />
        </div>
      )} */}

 

      <select
        name="minArea"
        value={filters.minArea}
        onChange={handleInputChange}
        className="text-primary w-18 font-light font-helvetica md:block sm:hidden bg-inherit focus:outline-none"
      >
        <option value="" selected disabled className="text-blue-gray-50">
          +20 km
        </option>
        {Constant.AREA.map((item, idx) => (
          <option key={idx} value={item.value} className="text-primary">
            {item.label}
          </option>
        ))}
      </select>
      <div className="w-[1px]  h-full bg-gray-500 md:block sm:hidden" />
      <div className="md:flex gap-x-5 items-center sm:hidden">
        <p className="text-primary text-xl cursor-default font-extralight">€</p>
        <input
          name="minPrice"
          value={filters.minPrice}
          onChange={handleInputChange}
          className="text-primary font-light w-14 font-helvetica bg-inherit focus:outline-none"
          placeholder="Preis"
        />
      </div>
      <div className="w-[1px] h-full bg-gray-500 md:block sm:hidden" />
      <div className="md:flex gap-x-5 items-center sm:hidden">
        <Box />
        <select
          name="rooms"
          value={filters.rooms}
          onChange={handleInputChange}
          className="text-primary w-18 font-light font-helvetica md:block sm:hidden bg-inherit focus:outline-none"
        >
          <option value="" selected disabled className="text-blue-gray-50">
            Zimmer
          </option>
          {Constant.ROOMS.map((item, idx) => (
            <option key={idx} value={item.value} className="text-primary">
              {item.label}
            </option>
          ))}
        </select>
      </div>
      <div className="w-[1px] h-full bg-gray-500" />
      <button
        className="flex gap-2 items-center text-primary font-light font-helvetica border border-1 border-primary px-8 h-full rounded-full"
        onClick={handleShowFilter}
      >
        <Filter />
        Filter
      </button>
      <button
        className="md:block sm:hidden font-light font-helvetica text-white bg-primary px-8 h-full rounded-full"
        onClick={handleFilter}
      >
        Suchen
      </button>
    </div>
  );
};
