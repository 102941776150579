import { useRef, useState } from "react";
import { Carousel, Dialog, Radio } from "@material-tailwind/react";

import "@geoapify/geocoder-autocomplete/styles/minimal.css";

//import services
import { useAddAdvertiseMutation } from "../../Services/AdvertiseService";

//import json
import phoneCodes from "../../Util/phone.json";
import countryCodes from "../../Util/country.json";
import { Constant } from "../../Constant";

//import custom components
import { Button, LinkButton } from "../Buttons";
import {
  TextInput,
  Dropdown,
  Checkbox,
  DropdownInput,
  RangeSliderComponent,
} from "../FormElements";
import { CountrySelect } from "../CountrySelect";

import { Loader } from "../Loader";
import { Alert } from "../Alert";

//import assets
import DocumentIcon from "../../Assets/document";
import RedClose from "../../Assets/RedClose";
import Room from "../../Assets/Room";
import MapPin from "../../Assets/MapPin";
import Image from "../../Assets/Image";
import Documents from "../../Assets/Documents";
import { useApp } from "../../Context";
import { useLocation, useNavigate } from "react-router-dom";

export const FilterModal = ({ open, handler }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { setFilters, filters } = useApp();

  //handle input values
  const handleInput = (e) => {
    const { name, value } = e.target;

    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //handle checkbox values
  const handleCheckBox = (val) => {
    const isChecked = filters.furnishing.includes(val);

    if (isChecked) {
      const updatedFurnishing = filters.furnishing.filter(
        (item) => item !== val
      );
      setFilters((prevState) => ({
        ...prevState,
        furnishing: updatedFurnishing,
      }));
    } else {
      setFilters((prevState) => ({
        ...prevState,
        furnishing: [...prevState.furnishing, val],
      }));
    }
  };

  const handlePriceRange = (val) => {
    setFilters((prevState) => ({
      ...prevState,
      minPrice: val[0],
      maxPrice: val[1],
    }));
  };

  const handleAreaRange = (val) => {
    setFilters((prevState) => ({
      ...prevState,
      minArea: val[0],
      maxArea: val[1],
    }));
  };

  const handleFilter = (e) => {
    e.preventDefault();

    if (pathname === "/filter") {
      handler();
    } else {
      handler();
      navigate("/filter");
    }
  };

  const clearFilter = () => {
    setFilters({
      city: "",
      rooms: "",
      minArea: "",
      maxArea: "",
      minPrice: "",
      maxPrice: "",
      furnishing: "",
      minConstructionYear: "",
      maxConstructionYear: "",
      advertise_type: "",
      category: "",
    });
  };

  const handleSelectCity = (city) => {
    setFilters((prevState) => ({
      ...prevState,
      city: city,
    }));
  };

  return (
    <Dialog
      open={open}
      handler={handler}
      className="bg-background"
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.9, y: -100 },
      }}
    >
      <div className="p-3 flex justify-between items-center">
        <p className="text-[18px] font-helvetica font-bold text-primary">
          Alle Filter (Gesuch verfeinern)
        </p>
        <a
          onClick={handler}
          className="w-8 h-8 flex justify-center items-center rounded-full bg-lightRed"
        >
          <RedClose />
        </a>
      </div>
      <div className="w-full h-[1px] bg-[#073937]" />
      <div className="p-3 lg:max-h-[700px] max-h-[400px] overflow-auto">
        <form className="flex flex-col gap-3" onSubmit={handleFilter}>
          <div>
            <p className="text-[16px] font-helvetica font-bold text-primary">
              Typ
            </p>
            <div className="flex gap-16">
              <Radio
                name="Werbetyp"
                label={
                  <p className="font-helvetica font-normal text-primary">
                    Mieten
                  </p>
                }
                value="Mieten" // Set the value to the desired option
                checked={filters.advertise_type === "Mieten"} // Add a checked prop to indicate the current state
                onChange={() =>
                  setFilters({ ...filters, advertise_type: "Mieten" })
                }
                icon={<div className="w-3 h-3 rounded-full bg-primary" />}
                className="border-2 border-primary bg-white p-0 m-0 transition-all hover:before:opacity-0 focus:border-primary"
                ripple={false}
              />

              <Radio
                name="Werbetyp"
                value="Kaufen" // Set the value to the desired option
                checked={filters.advertise_type === "Kaufen"} // Add a checked prop to indicate the current state
                onChange={() =>
                  setFilters({ ...filters, advertise_type: "Kaufen" })
                }
                label={
                  <p className="font-helvetica font-normal text-primary">
                    Kaufen
                  </p>
                }
                icon={<div className="w-3 h-3 rounded-full bg-primary" />}
                className="border-2 border-primary bg-white p-0 transition-all hover:before:opacity-0 focus:border-primary"
                ripple={false}
              />
            </div>
          </div>
          <CountrySelect
            name="city"
            label="Standort"
            placeholder="Suchen"
            handleSelect={handleSelectCity}
          />
          <RangeSliderComponent
            name="Preis"
            value={[filters.minPrice, filters.maxPrice]}
            min={filters.advertise_type === "Mieten" ? 100 : 10000}
            max={filters.advertise_type === "Mieten" ? 5000 : 5000000}
            step={10}
            onInput={(val) => handlePriceRange(val)}
            labels={["Preis von", "Preis bis"]}
          />
          {/* <RangeSliderComponent
            name="Fläche"
            value={[filters.minArea, filters.maxArea]}
            min={1}
            max={50}
            onInput={(val) => handleAreaRange(val)}
            labels={["Fläche von", "Fläche bis"]}
          /> */}
          <div className="grid grid-cols-2 gap-3">
            <TextInput
              name="name"
              value={filters.name}
              onChange={handleInput}
              label="Vor- und Nachname"
              type="name"
              placeholder="Max Mustermann"
            />
            <TextInput
              name="mail"
              value={filters.mail}
              onChange={handleInput}
              label="E-Mail"
              type="email"
              placeholder="max@mustermann.de"
            />
          </div>

          <div className="grid grid-cols-1 gap-3">
            <TextInput
              name="notes"
              value={filters.notes}
              onChange={handleInput}
              label="Anmerkungen zum Gesuch"
              type="text"
              placeholder="z.B. großer Garten"
            />
          </div>
          
          
          <div className="grid grid-cols-2 gap-3">
            <TextInput
              name="minArea"
              defaultValue={filters.minConstructionYear}
              onChange={handleInput}
              label="Fläche von"
              type="number"
              keyboardType='numeric'
              placeholder="Fläche von"
            />
            <TextInput
              name="maxArea"
              defaultValue={filters.minConstructionYear}
              onChange={handleInput}
              label="Fläche bis"
              type="number"
              keyboardType='numeric'
              placeholder="Fläche bis"
            />
          </div>
          <div className="grid grid-cols-2 gap-3">
            {/* <select>

            </select> */}
            <Dropdown
              data={Constant.NEW_CATEGORY}
              value={filters.category}
              name="category"
              label="Kategorie"
              onChange={handleInput}
              required
            />
            <Dropdown
              data={
                Constant.NEW_CATEGORY.find(
                  (item) => item.value === filters.category
                )?.sub
              }
              value={filters.sub_category}
              name="sub_category"
              label="Typ"
              onChange={handleInput}
              required
            />
          </div>
          <div className="grid grid-cols-2 gap-3">
            <TextInput
              name="minConstructionYear"
              value={filters.minConstructionYear}
              onChange={handleInput}
              label="Baujahr von"
              type="date"
            />
            <TextInput
              name="maxConstructionYear"
              value={filters.maxConstructionYear}
              onChange={handleInput}
              label="Baujahr bis"
              type="date"
            />
          </div>
          <div className="grid grid-cols-2 gap-3">
            <Dropdown
              name="Nutzfläche ab"
              label="Nutzfläche ab"
              data={Constant.FLOW_SPACE}
            />
            <Dropdown
              name="Nutzfläche bis"
              label="Nutzfläche bis"
              data={Constant.FLOW_SPACE}
            />
          </div>
          <p className="text-[16px] font-helvetica font-bold text-primary">
            Ausstattung
          </p>
          <div className="grid grid-cols-2 gap-3 ">
            {Constant.FURNISHING.map((item, idx) => (
              <Checkbox
                key={idx}
                label={item}
                onClick={() => handleCheckBox(item)}
                checked={filters.furnishing.includes(item)}
              />
            ))}
          </div>
          <div className="w-full h-[1px] bg-[#0739374D]" />
          <div className="flex justify-between gap-5">
            <button
              className="font-light font-helvetica border border-1 border-red-600 text-[16px] px-8 py-3 rounded-full w-full text-center"
              onClick={clearFilter}
            >
              Filter zurücksetzen
            </button>
            <Button title="Suchen" type="submit" variant="primary" full />
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export const PreviewModal = ({ open, handleClose, data }) => {
  return (
    <Dialog
      open={open}
      handler={handleClose}
      className="bg-background"
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.9, y: -100 },
      }}
    >
      <div className="p-3 flex justify-between items-center">
        <p className="text-[18px] font-helvetica font-bold text-primary">
          Inserat
        </p>
        <a
          onClick={handleClose}
          className="w-8 h-8 flex justify-center items-center rounded-full bg-lightRed"
        >
          <RedClose />
        </a>
      </div>
      <div className="w-full h-[1px] bg-[#a6a8a7]" />
      <div className="p-3 lg:max-h-[700px] max-h-[400px] overflow-auto">
        <div className="flex flex-col gap-2">
          <div className="lg:h-96 sm:h-44">
            <Carousel>
              {data.photos.map((item, idx) => (
                <img
                  key={idx}
                  src={`${process.env.REACT_APP_API_URL}uploads/${item}`}
                  alt="image 1"
                  className="h-full w-full object-cover"
                />
              ))}
            </Carousel>
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-lg font-helvetica font-bold text-primary">
              {data.title}
            </p>
            <p className="text-[16px] font-normal font-helvetica text-primary">
              {data.description}
            </p>
            <div className="flex lg:flex-row sm:flex-wrap gap-4 lg:items-center">
              <span className="flex items-center gap-2">
                <Room />
                <p className="text-[16px] font-normal font-helvetica text-primary">
                  {data.rooms} rooms
                </p>
              </span>

              {/* <p className="text-[16px] font-normal font-helvetica text-primary">
                3 bathrooms
              </p>
              <p className="text-[16px] font-normal font-helvetica text-primary">
                terrace
              </p>
              <p className="text-[16px] font-normal font-helvetica text-primary">
                garage
              </p>
              <p className="text-[16px] font-normal font-helvetica text-primary">
                114m living space
              </p> */}
            </div>
            <span className="flex items-center gap-2">
              <MapPin />
              <p className="text-[16px] font-normal font-helvetica text-primary">
                Zweibrückenstraße 12 80331 Munich, Germany
              </p>
            </span>
            <span className="flex items-center gap-2">
              <DocumentIcon />
              <p className="text-[16px] font-normal font-helvetica text-primary">
                Energieausweis
              </p>
            </span>
            <p className="text-lg font-helvetica font-bold text-primary">
              Kosten
            </p>
            <div className="grid grid-cols-2">
              <p className="text-[16px] font-bold font-helvetica text-primary">
                Total
              </p>
              <p className="text-[16px] font-bold font-helvetica text-primary text-end">
                {data.price} €
              </p>
            </div>
            <div className="w-full h-[1px] bg-[#a6a8a7]" />
            <p className="text-lg font-helvetica font-bold text-primary">
              Informationen
            </p>
            <div className="grid grid-cols-2">
              <p className="text-[16px] font-normal font-helvetica text-primary">
                Zimmer
              </p>
              <p className="text-[16px] font-normal font-helvetica text-primary text-end">
                {data.rooms}
              </p>
              <p className="text-[16px] font-normal font-helvetica text-primary">
                Fläche
              </p>
              <p className="text-[16px] font-normal font-helvetica text-primary text-end">
                {data.area}
              </p>
              <p className="text-[16px] font-normal font-helvetica text-primary">
                Adresse
              </p>
              <p className="text-[16px] font-normal font-helvetica text-primary text-end">
                {data.address}
              </p>
              <p className="text-[16px] font-normal font-helvetica text-primary">
                Stadt
              </p>
              <p className="text-[16px] font-normal font-helvetica text-primary text-end">
                {data.city}
              </p>
              <p className="text-[16px] font-normal font-helvetica text-primary">
                Baujahr
              </p>
              <p className="text-[16px] font-normal font-helvetica text-primary text-end">
                {data.construction_year}
              </p>
              <p className="text-[16px] font-normal font-helvetica text-primary">
                Letzte Renovierung
              </p>
              <p className="text-[16px] font-normal font-helvetica text-primary text-end">
                {data.last_renovation}
              </p>
            </div>
            <div className="w-full h-[1px] bg-[#a6a8a7]" />
            <p className="text-lg font-helvetica font-bold text-primary">
              Ausstattung
            </p>
            <div className="flex flex-wrap gap-2">
              {data.furnishing.map((item, idx) => (
                <p
                  key={idx}
                  className="text-[16px] font-normal font-helvetica text-primary"
                >
                  {item.trim()}
                  <span className="text-gray">{" | "}</span>
                </p>
              ))}
            </div>
            <p className="text-lg font-helvetica font-bold text-primary">
              Kontaktinformationen
            </p>
            <div className="grid grid-cols-2">
              <p className="text-[16px] font-normal font-helvetica text-primary">
                Telefonnummer
              </p>
              <p className="text-[16px] font-normal font-helvetica text-primary text-end">
                {data.telephone_number}
              </p>
              <p className="text-[16px] font-normal font-helvetica text-primary">
                Name
              </p>
              <p className="text-[16px] font-normal font-helvetica text-primary text-end">
                {data.sure_name}
              </p>
              <p className="text-[16px] font-normal font-helvetica text-primary">
                E-Mail Adresse
              </p>
              <p className="text-[16px] font-normal font-helvetica text-primary text-end">
                {data.email}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full h-[1px] bg-[#a6a8a7]" />
        <div className="mt-3 flex justify-center">
          <LinkButton
            href={`mailto:${data.email}`}
            target="_"
            title="E-Mail senden"
            variant="primary"
          />
        </div>
      </div>
    </Dialog>
  );
};

export const AdvertiseModal = ({ open, handler }) => {
  const formRef = useRef(null);
  //services
  const [addAdvertise, { isLoading }] = useAddAdvertiseMutation();

  //advertise state
  const [state, setState] = useState({
    title: "",
    description: "",
    rooms: "",
    address: "",
    city: "",
    area: "",
    price: "",
    availability: "",
    advertise_type: "",
    additional_cost: false,
    house_type: "",
    apartment_type: "",
    construction_year: "",
    documents: [],
    photos: null,
    furnishing: [],
    sure_name: "",
    email: "",
    telephone_number: "",
    last_renovation: "",
    category: "",
    sub_category: "",
  });
  const [error, setError] = useState({
    type: "",
    text: "",
    isShow: false,
  });

  //handle input values
  const handleInput = (e) => {
    const { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //handle checkbox values
  const handleCheckBox = (val) => {
    const isChecked = state.furnishing.includes(val);

    if (isChecked) {
      const updatedFurnishing = state.furnishing.filter((item) => item !== val);
      setState((prevState) => ({
        ...prevState,
        furnishing: updatedFurnishing,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        furnishing: [...prevState.furnishing, val],
      }));
    }
  };

  const handleSelectCity = (city) => {
    setState((prevState) => ({
      ...prevState,
      city: city,
    }));
  };

  //submit advertisement
  const handleSubmit = async (e) => {
    e.preventDefault();
    const frmData = new FormData();
    frmData.append("title", state.title);
    frmData.append("description", state.description);
    frmData.append("rooms", state.rooms);
    frmData.append("address", state.address);
    frmData.append("city", state.city);
    frmData.append("area", state.area);
    frmData.append("price", state.price);
    frmData.append("additional_cost", state.additional_cost);
    frmData.append("apartment_type", state.apartment_type);
    frmData.append("house_type", state.house_type);
    frmData.append("construction_year", state.construction_year);
    frmData.append("availability", state.availability);
    for (let i = 0; i < state.photos?.length; i++) {
      frmData.append("photos", state.photos[i]);
    }
    frmData.append("furnishing", state.furnishing);
    frmData.append("sure_name", state.sure_name);
    frmData.append("email", state.email);
    frmData.append("telephone_number", state.telephone_number);
    frmData.append("last_renovation", state.last_renovation);
    frmData.append("advertise_type", state.advertise_type);
    frmData.append("category", state.category);
    frmData.append("sub_category", state.sub_category);

    try {
      const res = await addAdvertise(frmData);

      //if failed
      if (res.error) {
        setError({
          type: "Warning",
          text: res.error.data.message,
          isShow: true,
        });
        return;
      }
      //if success
      if (res.data.code === 200) {
        setError({
          type: "Success",
          text: res.data.message,
          isShow: true,
        });
        clearForm();
      }
    } catch (error) {
      console.log("catch error :", error);
    }
  };

  //clear form
  const clearForm = () => {
    setState({
      title: "",
      description: "",
      rooms: "",
      address: "",
      area: "",
      price: "",
      availability: "",
      additional_cost: false,
      house_type: "",
      apartment_type: "",
      construction_year: "",
      documents: [],
      photos: null,
      furnishing: [],
      sure_name: "",
      email: "",
      telephone_number: "",
      last_renovation: "",
      advertise_type: "",
      category: "",
    });
  };

  return (
    <Dialog
      open={open}
      className="bg-background"
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.9, y: -100 },
      }}
    >
      <div className="p-3 flex justify-between items-center">
        <p className="text-[18px] font-helvetica font-bold text-primary">
          Neues Inserat
        </p>
        <a
          onClick={handler}
          className="w-8 h-8 flex justify-center items-center rounded-full bg-lightRed"
        >
          <RedClose />
        </a>
      </div>
      <div className="w-full h-[1px] bg-[#073937]" />
      <div className="p-3 lg:max-h-[700px] max-h-[400px] overflow-auto">
        <p className="text-[20px] font-helvetica font-bold text-primary">
          Alle Attribute
        </p>
        <form
          ref={formRef}
          className="flex flex-col gap-3 mt-5"
          onSubmit={handleSubmit}
        >
          <TextInput
            name="title"
            label="Titel"
            placeholder="Eingeben..."
            value={state.title}
            onChange={handleInput}
            required
          />
          <TextInput
            name="description"
            label="Beschreibung"
            placeholder="Eingeben..."
            value={state.description}
            onChange={handleInput}
            multiline
            required
          />
          <div className="grid grid-cols-2 gap-3">
            {/* <select>

            </select> */}
            <Dropdown
              data={Constant.NEW_CATEGORY}
              value={state.category}
              name="category"
              label="Kategorie"
              onChange={handleInput}
              required
            />
            <Dropdown
              data={
                Constant.NEW_CATEGORY.find(
                  (item) => item.value === state.category
                )?.sub
              }
              value={state.sub_category}
              name="sub_category"
              label="Typ"
              onChange={handleInput}
              required
            />
          </div>
          <Dropdown
            data={Constant.ROOMS}
            name="rooms"
            label="Zimmer"
            value={state.rooms}
            onChange={handleInput}
          />
          <TextInput
            name="area"
            label="Fläche (m²)"
            placeholder="50"
            value={state.area}
            onChange={handleInput}
            required
          />

          <TextInput
            name="address"
            label="Adresse"
            placeholder="z.B. Musterstraße 12, 12345 Musterstadt"
            value={state.address}
            onChange={handleInput}
            required
          />
          <CountrySelect
            name="city"
            label="Standort"
            placeholder="Suchen"
            handleSelect={handleSelectCity}
            required
          />
          <div>
            <p className="text-[16px] font-helvetica font-bold text-primary italic">
              Verfügbarkeit
            </p>
            <div className="flex gap-16">
              <Radio
                name="Verfügbarkeit"
                label={
                  <p className="text-[16px] font-helvetica font-normal">
                    Sofort
                  </p>
                }
                value={state.availability}
                onChange={() => setState({ ...state, availability: "Sofort" })}
                icon={<div className="w-3 h-3 rounded-full bg-primary" />}
                className="border-2 border-primary bg-white p-0 m-0 transition-all hover:before:opacity-0 focus:border-primary"
                ripple={false}
              />
              <Radio
                name="Verfügbarkeit"
                onChange={() =>
                  setState({ ...state, availability: "Nach Vereinbarung" })
                }
                label={
                  <p className="text-[16px] font-helvetica font-normal">
                    Nach Vereinbarung
                  </p>
                }
                value={state.availability}
                icon={<div className="w-3 h-3 rounded-full bg-primary" />}
                className="border-2 border-primary bg-white p-0 transition-all hover:before:opacity-0 focus:border-primary"
                ripple={false}
              />
            </div>
          </div>
          <div>
            <p className="text-[16px] font-helvetica font-bold text-primary italic">
              Typ
            </p>
            <div className="flex gap-16">
              <Radio
                name="Werbetyp"
                label={
                  <p className="text-[16px] font-helvetica font-normal">
                    Mieten
                  </p>
                }
                value={state.advertise_type}
                onChange={() =>
                  setState({ ...state, advertise_type: "Mieten" })
                }
                icon={<div className="w-3 h-3 rounded-full bg-primary" />}
                className="border-2 border-primary bg-white p-0 m-0 transition-all hover:before:opacity-0 focus:border-primary"
                ripple={false}
              />
              <Radio
                name="Werbetyp"
                onChange={() =>
                  setState({ ...state, advertise_type: "Kaufen" })
                }
                label={
                  <p className="text-[16px] font-helvetica font-normal">
                    Kaufen
                  </p>
                }
                value={state.advertise_type}
                icon={<div className="w-3 h-3 rounded-full bg-primary" />}
                className="border-2 border-primary bg-white p-0 transition-all hover:before:opacity-0 focus:border-primary"
                ripple={false}
              />
            </div>
          </div>
          <div className="w-full h-[1px] bg-[#0739374D]" />
          <p className="text-[20px] font-helvetica font-bold text-primary">
            Preis
          </p>
          <div className="flex flex-col gap-3">
            <DropdownInput
              name="price"
              value={state.price}
              label="Kosten"
              placeholder="Eingeben"
              data={countryCodes.map(({ code }) => ({
                label: code,
                value: code,
              }))}
              selectedValue="EUR"
              onChangeText={handleInput}
              required
            />
            <Checkbox
              name="additional_cost"
              label="Nebenkosten enthalten"
              checked={state.additional_cost}
              onClick={() =>
                setState({ ...state, additional_cost: !state.additional_cost })
              }
            />
          </div>
          <div className="w-full h-[1px] bg-[#0739374D]" />
          <p className="text-[20px] font-helvetica font-bold text-primary">
            Details
          </p>


          <div className="grid grid-cols-2 gap-3">
            <TextInput
              value={state.construction_year}
              name="construction_year"
              label="Baujahr"
              type="date"
              onChange={handleInput}
              required
            />
            <TextInput
              value={state.last_renovation}
              name="last_renovation"
              label="Letzte Renovierung"
              onChange={handleInput}
              type="date"
            />
          </div>
          <p className="text-[20px] font-helvetica font-bold text-primary">
            Ausstattung
          </p>
          <div className="grid grid-cols-2 gap-3 ">
            {Constant.FURNISHING.map((item, idx) => (
              <Checkbox
                key={idx}
                label={item}
                onClick={() => handleCheckBox(item)}
                checked={state.furnishing.includes(item)}
              />
            ))}
          </div>
          <div className="w-full h-[1px] bg-[#0739374D]" />
          <p className="text-[20px] font-helvetica font-bold text-primary">
            Fotos
          </p>
          <div className="flex gap-3">
            <label className="border border-dashed border-primary w-full h-32 rounded-md flex flex-col justify-center items-center gap-2 bg-[#0739371A]">
              <Image />
              <p className="text-sm font-helvetica text-primary">
                Fotos hochladen
              </p>
              <p className="text-xs font-helvetica text-primary">
                PNG, JPG or JPEG
              </p>
              <input
                type="file"
                className="hidden"
                onChange={(e) => setState({ ...state, photos: e.target.files })}
                multiple
                accept="image/png, image/jpeg"
              />
            </label>
            <label className="border border-dashed border-primary w-full h-32 rounded-md flex flex-col justify-center items-center gap-2 bg-[#0739371A]">
              <Documents />
              <p className="text-sm font-helvetica text-primary">
                Dokumente hochladen
              </p>
              <p className="text-xs font-helvetica text-primary">PDF</p>
              {/* <Button title="Auswählen" variant="primary" /> */}
              <input type="file" className="hidden" />
            </label>
          </div>
          <div className="w-full h-[1px] bg-[#0739374D]" />
          <p className="text-[20px] font-helvetica font-bold text-primary">
            Kontaktinformationen
          </p>
          <div className="grid grid-cols-2 gap-3">
            <TextInput
              name="sure_name"
              value={state.sure_name}
              label="Name"
              placeholder="Eingeben"
              onChange={handleInput}
              required
            />
            <TextInput
              name="email"
              value={state.email}
              label="E-Mail Adresse"
              placeholder="Eingeben"
              onChange={handleInput}
              required
            />
          </div>
          <DropdownInput
            name="telephone_number"
            value={state.telephone_number}
            label="Telefonnummer"
            placeholder="Eingeben"
            data={phoneCodes.map(({ code }) => ({
              label: code,
              value: code,
            }))}
            selectedValue="+49"
            onChangeText={handleInput}
            required
          />
          <div className="w-full h-[1px] bg-[#0739374D]" />
          <Button type="submit" title="Erstellen" variant="primary" />
        </form>
      </div>
      <Loader isLoading={isLoading} text="Please wait..." />
      <Alert
        isPopup={error.isShow}
        handlePopup={() => setError({ isShow: false })}
        type={error.type}
        text={error.text}
      />
    </Dialog>
  );
};
