import { ClockLoader } from "react-spinners";
import classNames from "classnames";

export const Loader = ({ isLoading = false, text }) => (
  <div
    className={classNames(
      "fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black p-4 opacity-0 invisible z-[60]",
      isLoading && "!visible opacity-70"
    )}
  >
    <div className="flex flex-col gap-2 items-center">
      <ClockLoader className="h-10 w-10" color="white" />
      <p className="text-sm font-helvetica text-white font-medium">Laden...</p>
    </div>
  </div>
);
