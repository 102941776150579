import * as React from "react";

function RightArrow(props) {
  return (
    <svg width={13} height={22} fill="none" {...props}>
      <g clipPath="url(#prefix__clip0_75_209)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.879 11L.939 3.06A1.5 1.5 0 113.061.94l9 9a1.5 1.5 0 010 2.12l-9 9a1.5 1.5 0 01-2.122-2.12L8.88 11z"
          fill="#889196"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_75_209">
          <path fill="#fff" transform="translate(.5)" d="M0 0h12v22H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default RightArrow;
