import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useApp } from "../../Context";

import { AdvertiseModal } from "../Modals";

//import assets
import Logo from "../../Assets/logo";
import Close from "../../Assets/Close";
import Menu from "../../Assets/Menu";

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();

  const { showAdModal, handleShowAdModal } = useApp();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="py-4 px-4 lg:px-12 w-full fixed top-0 left-0 bg-background z-10">
      <div className="md:flex items-center justify-between py-2 md:px-5 px-2">
        <Link to="/">
          <Logo />
        </Link>
        <div className="absolute right-4 top-6 md:hidden">
          <button onClick={toggleMenu}>{isOpen ? <Close /> : <Menu />}</button>
        </div>
        <ul
          className={`md:flex items-center gap-x-5 absolute md:static  left-0 md:w-auto w-full  md:pl-0 pl-7 bg-background md:bg-background transition-all duration-500 ease-in ${
            isOpen
              ? "top-[89px] opacity-100"
              : "top-[-490px] md:opacity-100 opacity-0"
          }`}
        >
          <li className="md:my-0 my-7 relative">
            <Link
              className="text-black hover:text-secondary font-helvetica text-[16px] "
              to="/"
            >
              Suchen
            </Link>
            {pathname === "/" && (
              <div className="lg:block sm:hidden w-[65%] bg-secondary h-[2.5px] transition delay-75 absolute bottom-[-10px] animate-slideIn" />
            )}
          </li>
          <li className="md:my-0 my-7 relative">
            <Link
              className="text-black hover:text-secondary font-helvetica text-[16px] "
              to="/about_us"
            >
              Über Uns
            </Link>
            {pathname === "/about_us" && (
              <div className="lg:block sm:hidden w-[65%] bg-secondary h-[2.5px] transition delay-75 absolute bottom-[-10px] animate-slideIn" />
            )}
          </li>
          <li className="md:my-0 my-7 relative">
            <a
              className="text-black hover:text-secondary font-helvetica text-[16px] "
              href="mailto:kontakt@immointerest.de"
            >
              Kontakt
            </a>
          </li>
          <li className="md:my-0 my-7">
            <Link
              className="text-black hover:text-secondary font-helvetica text-[16px]"
              onClick={handleShowAdModal}
            >
              Inserieren
            </Link>
          </li>
        </ul>
      </div>
      <AdvertiseModal open={showAdModal} handler={handleShowAdModal} />
    </nav>
  );
};
