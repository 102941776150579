import React from "react";

export const Footer = () => (
  <footer className="pt-12 pb-4 px-20 bg-primary">
    <div className="grid md:grid-cols-4 grid-rows-1 gap-2">
      <div className="flex flex-col gap-3">
        <p className="text-2xl text-white font-helvetica">ImmoInterest</p>
        <p className="text-white font-light font-helvetica">
          Eventstil GmbH<br/>
          Max-Eyth Straße 60<br/>
          72622 Nürtingen
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <p className="text-white text-lg font-normal font-helvetica">
          Rechtliches
        </p>
        <p className="text-white text-base font-light font-helvetica">
          <a href="/impressum.pdf">Impressum</a>
        </p>
        <p className="text-white text-base font-light font-helvetica">
        <a href="/datenschutz.pdf">Datenschutzerklärung</a>
        </p>
      </div>
      
    </div>
    <div className="mt-8">
      <div className="w-full h-[1px] bg-[#dee0e04d]" />
      <p className="text-white font-helvetica font-light mt-4">
        Ⓒ 2024 immointerests Inc. All rights reserved. Design by Stimm.DEV
      </p>
    </div>
  </footer>
);
