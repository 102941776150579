import { Routes, Route, RouterProvider } from "react-router-dom";

import Homepage from "./Pages/Home";
import DetailPage from "./Pages/Detail";
import AboutUsPage from "./Pages/AboutUs";
import ContactPage from "./Pages/Contact";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/detail/:city" element={<DetailPage />} />
      <Route path="/filter" element={<DetailPage />} />
      <Route path="/about_us" element={<AboutUsPage />} />
      <Route path="/contact" element={<ContactPage />} />
    </Routes>
  );
}

export default App;
