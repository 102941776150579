import React from "react";

import CardImg from "../../Assets/card-img.png";
import Room from "../../Assets/Room";
import MapPin from "../../Assets/MapPin";

export const HomeCard = ({ data, ...rest }) => (
  <a
    className="flex flex-col gap-2 transition ease-in-out delay-150  hover:scale-105 duration-300"
    {...rest}
  >
    <img
      src={`${process.env.REACT_APP_API_URL}uploads/${data.firstAdImage}`}
      alt="card-img"
      className="w-96  h-[200px] object-cover"
    />
    <p className="text-xs uppercase text-primary font-helvetica">
      {data.count} objekte
    </p>
    <p className="text-lg uppercase text-primary font-georgia">{data.city}</p>
  </a>
);

export const DetailCard = ({ data, ...rest }) => (
  <a
    className="flex lg:flex-row sm:flex-col gap-4 hover:bg-lightGreen lg:p-8 sm:p-4"
    {...rest}
  >
    <img
      src={`${process.env.REACT_APP_API_URL}uploads/${data.photos[0]}`}
      alt="card-img"
      className="lg:w-[250px] sm:w-full h-auto object-cover"
    />

    <div className="flex flex-col gap-4">
      <p className="text-[20px] font-bold font-helvetica">
        €{Number(data.price).toFixed(2)}
      </p>
      <div className="flex lg:flex-row sm:flex-wrap gap-4 lg:items-center">
        <span className="flex items-center gap-2">
          <Room />
          <p className="text-[16px] font-light font-helvetica text-primary">
            {data.rooms} Zimmer
          </p>
        </span>
        {/* <p className="text-[16px] font-light font-helvetica text-primary">
          3 bathrooms
        </p>
        <p className="text-[16px] font-light font-helvetica text-primary">
          terrace
        </p>
        <p className="text-[16px] font-light font-helvetica text-primary">
          garage
        </p>
        <p className="text-[16px] font-light font-helvetica text-primary">
          114m living space
        </p> */}
      </div>
      <span className="flex items-center gap-2">
        <MapPin />
        <p className="text-[16px] font-light font-helvetica text-primary">
          {data.address}
        </p>
      </span>
      <p className="text-[16px] font-bold font-helvetica">{data.title}</p>
      <p className="text-[16px] font-light font-helvetica text-primary">
        {data.description}
      </p>
    </div>
  </a>
);
