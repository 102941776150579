import React from "react";
import { Navbar } from "../../Components/Navbar";
import { TextInput } from "../../Components/FormElements";
import { Button, Footer } from "../../Components";

export default function ContactPage() {
  return (
    <>
      <Navbar />
      <div className="mt-[87px] mb-10 flex justify-center items-center">
        <div className="md:w-[600px] w-full sm:px-4">
          <p className="md:text-[25px] text-2xl font-semibold font-helvetica">
            Wie können wir dir helfen?
          </p>
          <p className="font-helvetica mt-10">
            Hast du Fragen oder Anregungen? Wir helfen dir gerne weiter.
            Kontaktiere uns dazu einfach über unser Kontaktformular. Wir werden
            uns so schnell wie möglich bei dir melden.
          </p>
          <form className="flex flex-col gap-3 mt-10">
            <TextInput label="Grund zur Kontaktaufnahm" required />
            <TextInput label="Anrede" required />
            <TextInput label="Vorname" required />
            <TextInput label="Name" required />
            <TextInput label="Mitteilung" multiline required />
            <Button title="Senden" variant="primary" />
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
